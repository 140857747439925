

$.mask.definitions.q = "[1,2,3,4,5,6,9]",
   $('input[type="tel"]').mask('+7 (q99) 999-99-99');

(function () {
   'use strict';
   window.addEventListener('load', function () {
      // Fetch all the forms we want to apply custom Bootstrap validation styles to
      var forms = document.getElementsByClassName('needs-validation');
      // Loop over them and prevent submission
      var validation = Array.prototype.filter.call(forms, function (form) {
         form.addEventListener('submit', function (event) {
            if (form.checkValidity() === false) {
               event.preventDefault();
               event.stopPropagation();
            }
            form.classList.add('was-validated');
         }, false);
      });
   }, false);
})();

// <!-- Tabs -->
(function ($) {
   $(function () {
      $('.switcher').delegate('.switcher__nav-link:not(.current)', 'click', function () {
         $(this).addClass('current').siblings().removeClass('current')
            .closest('section').find('.tabs__content').eq($(this).index())
            .addClass('visible').siblings().removeClass('visible');
      })
   })
})(jQuery);
// <!-- Tabs end -->

$('.tabs__tab-panes').each(function () {
   $(this).slick({
      slidesToShow: 1,
      arrows: false,
      fade: true,
      swipe: false,
      asNavFor: $(this).closest('.tabs__content').find('.tabs__nav-tabs'),
   });
});
$('.tabs__nav-tabs').each(function () {
   $(this).slick({
      slidesToShow: 'auto',
      slidesToScroll: 'auto',
      focusOnSelect: true,
      vertical: true,
      arrows: false,
      swipe: true,
      asNavFor: $(this).closest('.tabs__content').find('.tabs__tab-panes'),
      responsive: [
         {
            breakpoint: 991,
            settings: {
               slidesToShow: 3,
               vertical: false,
            }
         },
         {
            breakpoint: 767,
            settings: {
               slidesToShow: 2,
               vertical: false,
            }
         },
         {
            breakpoint: 500,
            settings: {
               slidesToShow: 1,
               vertical: false,
               variableWidth: true
            }
         },
      ]
   });
});

// quiz start
$(".quiz .variant").click(function () {
   if ($(this).find($("input:checked")).length > 0) {
      $(this).closest(".step.active").next().addClass("active"),
         $(this).closest(".step.active").removeClass("active");
      var s = $('.quiz').offset().top - 10; $("body,html").animate({ scrollTop: s }, 500);
      var i = $(this).closest(".quiz").find(".prg1 .line").attr("data-left");
      $(this).closest(".quiz").find(".prg1 .line").css("left", -1 * (parseInt(i) - 22) + "%"),
         $(this).closest(".quiz").find(".prg1 .line").attr("data-left", parseInt(i) - 22);
      var a = $(this).closest(".quiz").find(".current-step").html();
      $(this).closest(".quiz").find(".current-step").html(a - 1);
      if (a == 2) {
         $('#progress-text-1').html('Все готово. <strong>Остался последний шаг</strong>');
         //$('#progress-text').addClass('final-step');
         $(this).closest(".quiz-section").find(".quiz-section__title").html("Мы уже приступили к расчету <br> <b>стоимости и сроков шатра!</b>");
         $(this).closest(".quiz").addClass("quiz-finish");
         $(this).closest(".quiz-section").find(".quiz-section__subtitle-wrapper").css("display", "flex");
         $(this).closest(".quiz-section").find(".finish-off").css("display", "none");
         $(this).closest(".quiz-section").find(".finish-on").css("display", "block");
         var s = $('#quiz-section').offset().top; $("body,html").animate({ scrollTop: s }, 500);
      }
      if (a == 2) { $('.rp-rule__span').html('вопрос') } else
         if (a == 3 || a == 4 || a == 5) { $('.rp-rule__span').html('вопроса') } else {
            $('.rp-rule__span').html('вопросов')
         }
   }
});


$(".quiz-modal .variant").click(function () {
   if ($(this).find($("input:checked")).length > 0) {
      $(this).closest(".step.active").next().addClass("active"),
         $(this).closest(".step.active").removeClass("active");
      var i = $(this).closest(".quiz-modal").find(".prg1 .line").attr("data-left");
      $(this).closest(".quiz-modal").find(".prg1 .line").css("left", -1 * (parseInt(i) - 22) + "%"),
         $(this).closest(".quiz-modal").find(".prg1 .line").attr("data-left", parseInt(i) - 22);
      var a = $(this).closest(".quiz-modal").find(".current-step").html();
      $(this).closest(".quiz-modal").find(".current-step").html(a - 1);
      if (a == 2) {
         $('#progress-text-2').html('Все готово. <strong>Остался последний шаг</strong>');
         //$('#progress-text').addClass('final-step');
         $(this).closest(".quiz-modal").addClass("quiz-finish");
         $(this).closest(".quiz-modal").find(".quiz-section__title").html("Мы уже приступили к расчету <br> <b>стоимости и сроков шатра!</b>");
         $(this).closest(".quiz-modal").find(".quiz-section__subtitle-wrapper").css("display", "flex");
         $(this).closest(".quiz-modal").find(".finish-off").css("display", "none");
         $(this).closest(".quiz-modal").find(".finish-on").css("display", "block");
      }
      if (a == 2) { $('.rp-rule__span').html('вопрос') } else
         if (a == 3 || a == 4 || a == 5) { $('.rp-rule__span').html('вопроса') } else {
            $('.rp-rule__span').html('вопросов')
         }
   }
});

$('.next-step').click(function () {
   $(this).parent().find(".quiz-needs-select").addClass("visible-block")
});
//quiz end


Fancybox.defaults.autoFocus = 0;
Fancybox.defaults.trapFocus = 0;


// work time
var date = new Date();
var hourr = date.getHours();
var week = date.getDay();

if ((week >= 1 && week <= 5 && (hourr < 9 || hourr > 18)) || week == 0 || week == 6) {
   $('.contacts__working').css('display', 'none');
}
// work time end

// map через api
YaMapsShown = false;
$(document).ready(function () {

   $(window).scroll(function () {
      if (!YaMapsShown) {
         if ($(window).scrollTop() + $(window).height() > $(document).height() - 3000) {
            showYaMaps();
            YaMapsShown = true;
         }
      }
   });

});
function showYaMaps() {
   if ($(".map-wrapper").length) {
      ymaps.ready(init);
      function init() {
         var myMap1 = new ymaps.Map("YaMaps1", {
            center: [55.860943, 37.486409],
            zoom: 17
         });
         var myPlacemark1 = new ymaps.Placemark([55.860943, 37.486409], {
            iconCaption: 'ул. Лавочкина 19',
            hintContent: 'г. Москва, ул. Лавочкина 19, оф. 219-2',
            balloonContent: 'г. Москва, ул. Лавочкина 19, оф. 219-2'
         }, {
            preset: 'islands#redDotIconWithCaption'
         });
         myMap1.geoObjects.add(myPlacemark1);
         myMap1.behaviors.disable('scrollZoom');
         if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            myMap1.behaviors.disable('drag');
            myMap1.behaviors.disable('drag');
         }

         var myMap2 = new ymaps.Map("YaMaps2", {
            center: [54.559198, 36.264611],
            zoom: 17
         });
         var myPlacemark2 = new ymaps.Placemark([54.559198, 36.264611], {
            iconCaption: 'ул. Московская, д. 287 Б',
            hintContent: 'г. Калуга, ул. Московская, д. 287 Б',
            balloonContent: 'г. Калуга, ул. Московская, д. 287 Б'
         }, {
            preset: 'islands#redDotIconWithCaption'
         });
         myMap2.geoObjects.add(myPlacemark2);
         myMap2.behaviors.disable('scrollZoom');
         if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            myMap2.behaviors.disable('drag');
            myMap2.behaviors.disable('drag');
         }

         var myMap3 = new ymaps.Map("YaMaps3", {
            center: [45.073585, 38.930864],
            zoom: 17
         });
         var myPlacemark3 = new ymaps.Placemark([45.073585, 38.930864], {
            iconCaption: 'ул. 4-ая Линия, д. 110',
            hintContent: 'г. Краснодар, ул. 4-ая Линия, д. 110',
            balloonContent: 'г. Краснодар, ул. 4-ая Линия, д. 110'
         }, {
            preset: 'islands#redDotIconWithCaption'
         });
         myMap3.geoObjects.add(myPlacemark3);
         myMap3.behaviors.disable('scrollZoom');
         if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            myMap3.behaviors.disable('drag');
            myMap3.behaviors.disable('drag');
         }
      }
   }
};
// map end

